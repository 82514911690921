html {
  scroll-behavior: smooth
}

body {
  margin: 0;
  font-family: 'Khula', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
}

h1, h2, h3, h4, p {
  font-weight: 300;
}

a {
  text-decoration: none;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.backgroundimage {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-image: url("./img/mainbackground.png");
  background-position: top;
  background-attachment: local;
  background-repeat: no-repeat;
  opacity: 0.25;
  z-index: -1;
}

.backgroundcolor {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: black;
  background-position: top;
  background-attachment: local;
  background-repeat: no-repeat;
  z-index: -1;
}

.navbar {
  top: 0px;
  width: 100%;
  border-bottom: solid #dddddd 1px;
  color:white;
  background-color: rgb(36, 36, 36);
  position: fixed;
  z-index: 2;
}

.headercontainer {
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.logobox {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
}

.logobox:hover {
text-shadow: 0px 0px 15px #ffffff;
transition: 0.5s;
}

.menu {
  display: flex;
}

.menu a {
  display: flex;
  align-items: center;
  padding: 0 23px;
  color: #678391;
  font-size: 1.2rem;
  text-decoration: none;
}

.menu a:hover {
  background-color: #678391;
  color: black;
  text-shadow: 0px 0px 2px #374d58;
}

.menu button {
  display: none;
  font-size: 1.4rem;
  color: white;
  background-color: rgba(0,0,0,0);
  margin: 0 auto;
  padding: 25px;
  border: 0;
}

.menu button:hover {
  background-color: rgb(201, 201, 201);
  color: black;
  cursor: pointer;
}



.pages {
  color: white;
}

.marketgreetings {
  font-size: 2.5rem;
  text-transform: uppercase;
}

.pages a {
  color: #546e7a;
  text-decoration: underline;
}

.pages a:hover {
  color: white;
}

.pages button {
  color: #000000;
  margin: 30px 0;
  background-color: #546e7a;
  border-width: 0;
  border-radius: 2px;
  padding: 12px 25px;
}

.pages button:hover {
  background-color: #7b95a1;
}

.pages button:focus {
  outline: none;
}

.paper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 900px;
  height: 100vh;
  padding-top: 78.22px;
}

.contacts {
  color: white;
}

.contacts .contactrow {
  display: flex;
  padding: 10px 0;
}

.contacts .contactcol {
  padding-right: 20px;
}

.homepagebottom {
  box-sizing: border-box;
  color: black;
  text-align: center;
  height: 100vh;
  padding-top: 78.22px;
}

.homepagebottom h1 {
text-transform: uppercase;
}

.vidgetsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  
}

.vidgetsContainer div {
  width: 100%;
  height: 100%;  
}

.vidgetsContainer div h2 {
  margin: 0;
  margin-top: 20px;
  font-weight: 400;
  text-transform: uppercase;
}

.mountwrapper {
  max-width: 25vw;
  margin: 0 auto;
}

.mount {
  max-height: 25vw;
}

.mount canvas {
  outline: solid rgb(121, 121, 121) 2px;
  outline-style: dashed;
  opacity: 0.7;
}

.mount canvas:hover{
  /* outline: solid black 2px; */
  opacity: 1;
  outline-style: dashed;
}

.mount canvas:focus {
  /* outline: solid black 2px; */
  outline-style: dashed;
}


@media screen and (max-width: 900px) {
  .logobox {
    padding-left: 2rem;
  }
  .paper {
    padding-left: 2rem;
  }
  .vidgetsContainer {
    display: block;
    
  }
  .mountwrapper {
    max-width: 80vw;
    
  }
  .mount {
    max-height: 50vw;
  }
}

@media screen and (max-width: 600px) {
  .pages h1 {
    font-size: 1.5rem;
  }

  .pages h2 {
    font-size: 1rem;
  }

  .menu a {
    display: none;
  }

  .menu button {    
    display: block;
  }

  .navbar {
    border-bottom: none;
  }
}

@media screen and (max-width: 600px) {
  .pages.responsive {
    position: relative;
  }

  .logobox.responsive {
    display: none;
  }

  .menu.responsive {
    width: 100%;
    display: block;
  }

  .menu.responsive button {
    display: none;
  }

  .menu.responsive a {
    transition: 0.5s;
    display: block;
    padding: 15px;
    text-align: center;
  }
}